import React, { useCallback, useState } from 'react'
import Slider from 'react-slick'
import { Box, Typography } from '@mui/material'
import RelatedPostCard, {
  RELATED_POST_ORIENTATION_HORIZONTAL,
  RELATED_POST_ORIENTATION_VERTICAL
} from '../builder-blog-post/related-post-card'
import useMediaQuery from '@mui/material/useMediaQuery'
import { onDesktop } from '../../../../styles/theme-proven/responsive'
import propTypes from 'prop-types'

const EditorsPicks = ({ title, editorsPicksPosts }) => {
  const [dragging, setDragging] = useState(false)
  const isMdUp = useMediaQuery(theme => theme.breakpoints.up('md'))

  const sliderSettings = {
    slidesToScroll: 1,
    slidesToShow: 1.5,
    initialSlide: 1,
    centerPadding: '20px',
    infinite: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 1.06,
          centerPadding: '20px'
        }
      },
      {
        breakpoint: 899,
        settings: {
          slidesToShow: 2.06,
          centerPadding: '20px'
        }
      },
      {
        breakpoint: 599,
        settings: {
          slidesToShow: 1.15
        }
      }
    ]
  }

  const handleBeforeChange = useCallback(() => {
    setDragging(true)
  }, [setDragging])

  const handleAfterChange = useCallback(() => {
    setDragging(false)
  }, [setDragging])

  const handleOnItemClick = useCallback(
    e => {
      if (dragging) e.stopPropagation()
    },
    [dragging]
  )

  return (
    <Box>
      <Box>
        {title && (
          <Typography
            variant="h5"
            component="h5"
            textAlign="center"
            sx={{
              mb: 4,
              mt: 10,
              [onDesktop]: {
                my: 3
              }
            }}
          >
            {title}
          </Typography>
        )}
        <Box
          data-testid="editors-picks-slider-container-testId"
          sx={{
            marginLeft: -2,
            [onDesktop]: {
              marginLeft: 0,
              pl: 'calc((100% - 1200px)/2 - 20px);'
            }
          }}
        >
          <Slider
            {...sliderSettings}
            beforeChange={handleBeforeChange}
            afterChange={handleAfterChange}
          >
            {editorsPicksPosts.map(post => (
              <Box
                key={`editor-picks-post-${post?.id}`}
                data-testid="editors-picks-post-slider-item-container-testId"
                sx={{
                  px: 2,
                  [onDesktop]: {
                    px: 3
                  }
                }}
                onClickCapture={handleOnItemClick}
              >
                <Box
                  sx={{
                    backgroundColor: 'gray.white',
                    borderRadius: '8px',
                    p: [2, 2, 4, 2],
                    [onDesktop]: {
                      p: 4
                    }
                  }}
                >
                  <RelatedPostCard
                    key={post?.id}
                    title={post?.data?.title}
                    summary={post?.data?.summary}
                    seoTitle={post?.data?.seoTitle}
                    url={post?.data?.url}
                    featuredImage={post?.data?.featuredImage}
                    thumbnails={post?.data?.thumbnails}
                    categories={post?.data?.categories}
                    orientation={
                      isMdUp
                        ? RELATED_POST_ORIENTATION_HORIZONTAL
                        : RELATED_POST_ORIENTATION_VERTICAL
                    }
                    sx={{
                      maxHeight: isMdUp ? 'unset' : '424px',
                      minHeight: isMdUp ? 'unset' : '394px'
                    }}
                  />
                </Box>
              </Box>
            ))}
          </Slider>
        </Box>
      </Box>
    </Box>
  )
}

EditorsPicks.propTypes = {
  title: propTypes.string,
  editorsPicksPosts: propTypes.array.isRequired
}

export default EditorsPicks
